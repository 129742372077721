import { TasksActionTypes } from './constants';

type TasksAction = { type: string; payload: {} | string };

// common success
export const taskApiResponseSuccess = (actionType: string, data: any): TasksAction => ({
    type: TasksActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const taskApiResponseError = (actionType: string, error: string): TasksAction => ({
    type: TasksActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTasks = (): TasksAction => ({
    type: TasksActionTypes.GET_TASKS,
    payload: {},
});

export const getTaskById = (id: string): TasksAction => ({
    type: TasksActionTypes.GET_TASK_BY_ID,
    payload: { id },
});

export const getTasksByUser = (user: string): TasksAction => ({
    type: TasksActionTypes.GET_TASKS_BY_USER,
    payload: { user },
});

export const getTasksByTransaction = (id: string): TasksAction => ({
    type: TasksActionTypes.GET_TASKS_BY_TRANSACTION,
    payload: { id },
});

export const getTasksByListing = (id: string): TasksAction => ({
    type: TasksActionTypes.GET_TASKS_BY_LISTING,
    payload: { id },
});

export const createTask = (id: string, data: object): TasksAction => ({
    type: TasksActionTypes.CREATE_TASK,
    payload: { id, data },
});

export const updateTask = (id: string, data: object): TasksAction => ({
    type: TasksActionTypes.UPDATE_TASK,
    payload: { id, data },
});

export const deleteTask = (id: string): TasksAction => ({
    type: TasksActionTypes.DELETE_TASK,
    payload: { id },
});

export const archiveTask = (id: string): TasksAction => ({
    type: TasksActionTypes.ARCHIVE_TASK,
    payload: { id },
});

export const createTaskComment = (id: string, data: object): TasksAction => ({
    type: TasksActionTypes.CREATE_COMMENT,
    payload: { id, data },
});

export const createTaskAttachment = (id: string, data: object): TasksAction => ({
    type: TasksActionTypes.CREATE_ATTACHMENT,
    payload: { id, data },
});

export const resetTasks = (): TasksAction => ({
    type: TasksActionTypes.RESET_TASKS,
    payload: {},
});

export const resetTask = (): TasksAction => ({
    type: TasksActionTypes.RESET_TASK,
    payload: {},
});
